.manual-height {
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
}

.grid {
  height: 100vh;
}

.grid-item {
  text-align: center;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5% !important;
}

@media screen and (max-width: 1279px) {
  .grid-item {
    height: 50%;
  }
  .manual-height {
    width: 20%;
  }
}
