.simple-keyboard {
  pointer-events: none;
  height: 100%;
}
.react-simple-keyboard {
  background-color: #f9f9f9;
  color: #333;
  font-size: 30px;
  padding: 0px !important;
  border-radius: 10px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
}
.hg-button {
  height: 100% !important;
  border-radius: 5px; /* Rounded corners */
  transition: all 0.2s; /* Smooth transition for all changes */
}
.react-simple-keyboard .hg-button {
  background-color: #fff;
  color: #333;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 0px !important;
  box-sizing: border-box;
  pointer-events: all;
}
.react-simple-keyboard .hg-button:hover {
  background-color: #f2f2f2;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
}
.react-simple-keyboard .hg-button:active {
  background-color: #e0e0e0;
}
.react-simple-keyboard .hg-functionBtn {
  background-color: #f2f2f2;
  color: #333;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 0px !important;
  width: 10px;
  border-radius: 5px;
}
.hg-button-space {
  width: 60% !important;
}
.hg-button-bksp {
  width: fit-content !important;
}
.hg-rows {
  height: 100%;
  pointer-events: none;
}
.hg-row {
  height: var(--row-height-percentage);
  margin: 0px !important;
}
.keyboard-wrapper {
  z-index: 1000;
  height: 35%;
}
.keyboard-wrapper {
  width: 100vw;
  bottom: 0px;
  left: 0px;
  background: black;
  position: absolute;
  pointer-events: all;
  border-top-left-radius: 10px; /* Rounded top left corner */
  border-top-right-radius: 10px; /* Rounded top right corner */
}
