.icon {
  width: 50px;
  height: 50px;
  cursor: pointer;
  position: absolute;
  right: 0px;
}

.settingsIcon {
  width: 100%;
  height: auto;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden;
}

.button {
  font-size: 20px;
  color: rgb(250, 250, 250);
  background-color: #052679;
  border: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  margin: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 300px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.settingsCointainer {
  background-color: rgb(250, 250, 250);
  padding: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  border-radius: 5%;
  position: relative;
}

.numpadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(250, 250, 250);
  border-radius: 10px;
  padding: 20px;
}

.numpad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  width: 100%;
}

.numpadButton {
  background-color: #052679;
  color: rgb(250, 250, 250);
  border: none;
  border-radius: 10%;
  width: fit-content;
  min-width: 100px;
  height: 60px;
  font-size: 24px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.numpadButton:hover {
  background-color: #155a8a;
}
