.manual-height {
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
  width: 10%;
  height: 100%;
}

.grid-item {
  display: flex;
  max-width: 50% !important;
}

.adjusting-guide {
  width: 50%;
  margin: auto;
  font-size: 10%;
  text-align: center;
  padding: 5% !important;
  display: flex !important;
  align-items: center;
}
.adjust-height-container {
  display: block;
}
.start-scan-btn {
  background: #052679;
  height: 100px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: xx-large;
  color: #fff;
  margin: auto;
  font-weight: bold;
}
.video-guide {
  padding: 5% !important;
  height: 100%;
  width: 100%;
}
.video-guide video {
  height: 100%;
  width: 100%;
  object-fit: contain; /* Use contain if you want to see the whole video without cropping */
  margin: auto;
  padding: 30px;
}

.logo {
  position: fixed;
  top: 2%;
  right: 5%;
}
.text-guide-label {
  width: 50%;
  margin: auto;
  font-size: 25px;
  font-weight: 600;
}
.camera-preview {
  position: relative;
  left: 100px;
}
@media screen and (max-width: 1279px) {
  .adjusting-guide {
    height: 30%;
    width: 80%;
    padding: 18% !important;
  }

  .video-guide {
    height: 50%;
    padding: 5% !important;
  }
  .grid-item {
    height: 50%;
    max-width: 100% !important;
  }
  .grid {
    height: 90vh;
  }
  .manual-height {
    width: 20%;
  }
  .text-guide-label {
    font-size: 50px;
  }
  .camera-preview {
    position: static;
  }
}

.btn-container {
  width: 100%;
  display: flex;
  padding: 10px;
  position: absolute;
  bottom: 10px;
}

.moving-icon {
  position: relative;
  bottom: 0;
  animation: moveIcon 30s linear infinite;
}

@keyframes moveIcon {
  0% {
    bottom: 0;
  }
  100% {
    bottom: 100%;
  }
}
