.image {
  max-width: 100%;
  height: auto;
}

.stepper-container {
  width: 50%;
  margin: auto;
  border: 0px;
  overflow: hidden;
  border-radius: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.MuiPaper-root {
  background: #eef6ff !important;
}
.report-disease-list-container {
  height: 100% !important;
  overflow: auto;
  width: 80%;
}
