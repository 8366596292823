.manual-height {
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
  width: 10%;
  height: 100%;
}
.grid {
  height: 100vh;
}

.grid-item {
  text-align: center;
  height: 100%;
  width: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5% !important;
  max-width: 50% !important;
}

.angle-name {
  font-size: xx-large;
}

.camera-content {
  flex: 1;
  overflow: hidden;
}
@media screen and (max-width: 1279px) {
  .grid-item {
    height: 50%;
    width: 80% !important;
    max-width: 100% !important;
  }
  .manual-height {
    width: 20%;
  }
}
