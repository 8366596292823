.arrows {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: rgb(250, 250, 250);
}

.arrows img {
  width: 100%;
  user-select: none;
  transition: transform 0.2s ease-in-out;
}

@media screen and (max-width: 1279px) {
  .manual-text-guide {
  }
}

.arrows img.touchable:active {
  transform: scale(1.1);
}

.manual-text-guide {
  max-width: min-content;
  border-radius: 10px;
  color: #052679;
  font-size: 2em;
  text-align: center;
  margin: auto;
  margin-top: 20px;
  padding: 10px;
}
/* 
.arrows img.touchable:active {
  transform: scale(1.1);
  cursor: pointer;
  outline: none;
}

.arrows img.touchable:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.arrows img.touchable:focus {
  outline: none;
}

.arrows img.touchable:active {
  transform: scale(1.1);
  cursor: pointer;
  outline: none;
  border: none;
}

.arrows img.touchable {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
} */
